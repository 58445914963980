require('./bootstrap');
//require('./index.js');
import simpleParallax from 'simple-parallax-js';
window.simpleParallax = simpleParallax;

import { Fancybox } from "@fancyapps/ui";

//import slickCarousel from 'slick-carousel/slick/slick.min';
import isotope from 'isotope-layout/dist/isotope.pkgd';
window.Isotope = isotope;

import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';
window.InfiniteAjaxScroll = InfiniteAjaxScroll;

/*
** HELPER FUNCTIONS
*/
window.checkIos = () => {
    if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
    return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
};
window.masterEqHeight = function(elements, target, xs = 1, sm = 1, md = 2, lg = 3, xl = 4){

    var columns = xs;
    if ($(window).width() >= 576){
        columns = sm;
    }
    if ($(window).width() >= 768){
        columns = md;
    }
    if ($(window).width() >= 992){
        columns = lg;
    }
    if ($(window).width() >= 1200){
        columns = xl;
    }

    if (columns > 1){

        var products = $(elements);
        var length = products.length;
        var rows = Math.ceil(length / columns);
        var matrix = new Array(rows).fill(0);

        for(var i = 0, j = 0;i < length;i++){
            if (i > 0 && i % columns == 0) j++;
            if (products.eq(i).find(target).height() > matrix[j]){
                matrix[j] = products.eq(i).find(target).height();
            }
        }
        for(var i = 0, j = 0;i < length;i++){
            if (i > 0 && i % columns == 0) j++;
            products.eq(i).find(target).height(matrix[j]);
        }
    }
    
}


window.setOptionalCookies = function(cookies){

    $.ajax({

       type:'POST',
       url: '/api/setOptionalCookies',
       data:{cookies:cookies},

       success:function(data){
                
            data.forEach(function(element){
                if(element)
                    $('head').append(element);
            });
           
       },
       error: function( data ) {
               
            if ( data.status === 422 ) {
                    alert(data.msg);
            }
        }

    });

}



$(document).ready(function(){

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
	
	$('.navbar-toggler').on('click', function(){

        if (!$('.menu-mobile').hasClass('active')){
            $('.menu-mobile').addClass('active');
            $('.navbar-toggler').addClass('open');
        }else{
            $('.menu-mobile').removeClass('active');
            $('.navbar-toggler').removeClass('open');
        }
    });

});

$(window).on('scroll', function(){
    if ($(this).scrollTop() > 80){
        $('.navbar-laravel').addClass('scrolling');
    }else{
        $('.navbar-laravel').removeClass('scrolling');
    }
});


$(document).ready(function() {
    $('#lang-button-desktop').on('click', function(event) {
        event.preventDefault();
        $(this).addClass('expand');
    });
});